import React, { Component } from 'react';


export default class Loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {
        return (
            <div className="overlay">
                <div className="overlay__loading">
                    Loading ...
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import axios from 'axios';


export default class Rentallist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            timing: "",
            filter_status: "",
            rentals: [],
            rentals_filtered: []
        };

        this.baseUrl = this.props.baseUrl;
        this.updateRentalStatus = this.updateRentalStatus.bind(this);
        this.setTiming = this.setTiming.bind(this);
        this.setFilterStatus = this.setFilterStatus.bind(this);
        this.setFilterOverdue = this.setFilterOverdue.bind(this);
        this.filterItems = this.filterItems.bind(this);
    }


    async componentDidMount() {
        if (this.props.rentals_all && this.state.rentals.length < 1) {
            await this.setState({
                rentals: this.props.rentals_all
            });
            await this.filterItems();
        }
    }


    async componentDidUpdate() {
        if (this.props.rentals_all && this.state.rentals.length < 1) {
            await this.setState({
                rentals: this.props.rentals_all
            });
            await this.filterItems();
        }
    }


    async updateRentalStatus(rental, status_in) {
        // TO DO .....
        try {
            await axios.post(this.baseUrl + "/api/v001/updateorderstatus", {
                order: rental,
                status: status_in,
                hotel_id: this.props.hotelid
            })
                .then(res => {
                    var data = res.data;

                    if (data) {
                        // Update state
                        const updatedList = this.state.rentals.map((r) => {
                            if (rental === r) {
                                return { ...r, status: [...r.status, status_in] };
                            }
                            return r;
                        });
                        this.setState({
                            rentals: updatedList,
                            rentals_filtered: updatedList
                        });
                    } else {
                        // Track error
                        // TO DO
                    }
                })
                .catch(err => {
                    // Track error
                    // TO DO
                });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            // TO DO
            /* var name = "Website error";
            var message = "Website error"; */
            /* this.props.setError(name, message); */
            // Track event
            /* this.trackEvent("Client error", "Hotel_loadHotelData"); */
        }
    }


    async setTiming(timing) {
        await this.setState({
            timing: timing
        });
        await this.filterItems();
    }


    async setFilterStatus(status) {
        await this.setState({
            filter_status: status
        });
        await this.filterItems();
    }

    async setFilterOverdue() {
        this.setTiming("past");
        this.setFilterStatus("delivered");
    }


    async filterItems() {
        var rentals_filtered = [];
        if (this.state.timing === "") {
            rentals_filtered = this.props.rentals_all;
        }
        if (this.state.timing === "past") {
            rentals_filtered = this.props.rentals_past;
        }
        if (this.state.timing === "ongoing") {
            rentals_filtered = this.props.rentals_ongoing;
        }
        if (this.state.timing === "future") {
            rentals_filtered = this.props.rentals_future;
        }

        if (this.state.filter_status !== "") {
            rentals_filtered = rentals_filtered.filter((r) => {
                return r.status[r.status.length - 1] === this.state.filter_status;
            });
        }

        this.setState({
            rentals_filtered: rentals_filtered
        });
    }


    render() {
        return (
            <div className="rentallist-container">
                <div className="rentallist-wrapper">

                    <div className="rentallist__categories">
                        <div className="rentallist__categories__section">
                            <div className="rentallist__categories__title">
                                Quick filter:
                            </div>
                            <div onClick={() => this.setFilterOverdue()} className={"rentallist__categories__element" + (this.state.timing === "past" && (this.state.filter_status !== "returned" && this.state.filter_status !== "") ? " rentallist__categories__element--selected" : "")}>
                                Overdue items
                            </div>
                        </div>
                        <div className="rentallist__categories__section">
                            <div className="rentallist__categories__title">
                                Timing:
                            </div>
                            <div onClick={() => this.setTiming("")} className={"rentallist__categories__element" + (this.state.timing === "" ? " rentallist__categories__element--selected" : "")}>
                                All
                            </div>
                            <div onClick={() => this.setTiming("ongoing")} className={"rentallist__categories__element" + (this.state.timing === "ongoing" ? " rentallist__categories__element--selected" : "")}>
                                Ongoing
                            </div>
                            <div onClick={() => this.setTiming("past")} className={"rentallist__categories__element" + (this.state.timing === "past" ? " rentallist__categories__element--selected" : "")}>
                                Past
                            </div>
                            <div onClick={() => this.setTiming("future")} className={"rentallist__categories__element" + (this.state.timing === "future" ? " rentallist__categories__element--selected" : "")}>
                                Future
                            </div>
                        </div>
                        <div className="rentallist__categories__section">
                            <div className="rentallist__categories__title">
                                Status:
                            </div>
                            <div onClick={() => this.setFilterStatus("")} className={"rentallist__categories__element" + (this.state.filter_status === "" ? " rentallist__categories__element--selected" : "")}>
                                All
                            </div>
                            <div onClick={() => this.setFilterStatus("requested")} className={"rentallist__categories__element" + (this.state.filter_status === "requested" ? " rentallist__categories__element--selected" : "")}>
                                Requested
                            </div>
                            <div onClick={() => this.setFilterStatus("delivered")} className={"rentallist__categories__element" + (this.state.filter_status === "delivered" ? " rentallist__categories__element--selected" : "")}>
                                Delivered
                            </div>
                            <div onClick={() => this.setFilterStatus("returned")} className={"rentallist__categories__element" + (this.state.filter_status === "returned" ? " rentallist__categories__element--selected" : "")}>
                                Returned
                            </div>
                        </div>
                    </div>

                    <div className="rentallist__catalogue">

                        {this.state.rentals_filtered.length >= 1 && this.state.rentals_filtered.map((rental, id) =>
                            <div key={id} className="rentallist__catalogue__element">
                                {/* <div className="rentallist__catalogue__element__toggle"></div> */}
                                <div className="rentallist__catalogue__element__section">
                                    <div className="rentallist__catalogue__element__section__element">
                                        {rental.item_amount} x {rental.item_label}
                                    </div>
                                    <div className="rentallist__catalogue__element__section__element">
                                        Name: {rental.name}
                                    </div>
                                    <div className="rentallist__catalogue__element__section__element">
                                        Room: {rental.room}
                                    </div>
                                    <div className="rentallist__catalogue__element__section__element">
                                        {rental.date_from} - {rental.date_to}
                                    </div>
                                    <div className="rentallist__catalogue__element__section__element rentallist__catalogue__element__section__buttonbox">
                                        <div onClick={() => this.updateRentalStatus(rental, "requested")} className={"rentallist__catalogue__element__section__buttonbox__button" + (rental.status[rental.status.length - 1] === "requested" ? " button-primary" : " button-tertiary")}>
                                            Requested
                                        </div>
                                        <div onClick={() => this.updateRentalStatus(rental, "delivered")} className={"rentallist__catalogue__element__section__buttonbox__button" + (rental.status[rental.status.length - 1] === "delivered" ? " button-primary" : " button-tertiary")}>
                                            Delivered
                                        </div>
                                        <div onClick={() => this.updateRentalStatus(rental, "returned")} className={"rentallist__catalogue__element__section__buttonbox__button" + (rental.status[rental.status.length - 1] === "returned" ? " button-primary" : " button-tertiary")}>
                                            Returned
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ScreenVideo from '../../media/videos/home/home_hero_1.webm';
import Macbook from '../../media/images/home/hero_macbook.png';


export default class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {

        return (
            <div className="hero">
                <div className="content-wrapper hero-wrapper">
                    <div className="hero__content">
                        <div className="hero__content__title text-title text-color-light">
                            Rent out items to your hotel guests
                        </div>
                        <div className="hero__content__subtitle text-subtitle text-color-light">
                            Let your guests request rental or complimentary items during their stay.
                            <br />
                            Better service, more sustainable, fully efficient.
                        </div>
                        <Link to={'/contact'} className="hero__content__actionbutton button button--subtitle button-primary-light text-subtitle">
                            Get in touch
                        </Link>
                    </div>
                    <div className="hero__illustration">
                        <div className="hero__illustration__screen">
                            <img src={Macbook} className="hero__illustration__screen-outer" />
                            <div className="hero__illustration__screen-inner">
                                <video
                                    autoPlay
                                    loop
                                    muted  // Required for some browsers to allow autoplay
                                    className="hero__illustration__screen__video"
                                >
                                    <source src={ScreenVideo} type="video/webm" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

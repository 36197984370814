import React, { Component } from 'react';
import ref_harvard from '../../media/images/reference_harvard.png';
import ref_ilab from '../../media/images/reference_ilab.png';
import ref_mit from '../../media/images/reference_mit.png';
import ref_sandbox from '../../media/images/reference_sandbox.png';


export default class References extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };

    }


    render() {

        return (
            <div className="home__references-container">
                <div className="home__references-wrapper">
                    <img src={ref_harvard} className="home__references__reference" />
                    {/* <img src={ref_ilab} className="home__references__reference" /> */}
                    <img src={ref_mit} className="home__references__reference" />
                    <img src={ref_sandbox} className="home__references__reference" />
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import Item from './item.js';
import Checkout from './checkout.js';


export default class Shop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            filter_category: "",
            filter_available: "",
            filter_type: "",
            categories: [],
            items: this.props.items,
            items_filtered: [],
            cart: [],
            waitlist: [],
            checkout: false
        };

        this.getCategories = this.getCategories.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setType = this.setType.bind(this);
        this.setAvailability = this.setAvailability.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addWaitlist = this.addWaitlist.bind(this);
        this.removeWaitlist = this.removeWaitlist.bind(this);
        this.setCheckout = this.setCheckout.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
    }


    componentDidMount() {
        this.setState({
            items: this.props.items,
            items_filtered: this.props.items
        });
        this.getCategories();
    }


    componentDidUpdate() {
        if (this.props.items !== this.state.items) {
            this.setState({
                items: this.props.items,
                items_filtered: this.props.items
            });
            this.getCategories();
        }
    }


    getCategories() {
        var list = this.props.items;
        var categories = [...new Set(list.map(item => item.category))];
        this.setState({
            categories: categories
        });
    }


    async setCategory(category) {
        await this.setState({
            filter_category: category
        });
        await this.filterItems();
        this.props.logEvent('hotel', 'setCategory', category);
    }


    async setAvailability(day_from_today) {
        await this.setState({
            filter_available: day_from_today
        });
        await this.filterItems();
        this.props.logEvent('hotel', 'setAvailability', day_from_today);
    }


    async setType(type) {
        await this.setState({
            filter_type: type
        });
        await this.filterItems();
        this.props.logEvent('hotel', 'setType', type);
    }


    async filterItems() {
        var list_filtered = this.state.items;
        if (this.state.filter_category !== "") {
            list_filtered = this.state.items.filter(item => item.category === this.state.filter_category);
        }
        if (this.state.filter_type !== "") {
            list_filtered = list_filtered.filter(item => item.order_type === this.state.filter_type);
        }
        if (this.state.filter_available !== "") {
            list_filtered = list_filtered.filter(item => item.availability_remaining_list[this.state.filter_available].availability >= 1);
        }
        this.setState({
            items_filtered: list_filtered
        });
    }


    addItem(item) {
        this.removeItem(item);
        var cart = this.state.cart;
        cart.push(item);
        this.setState({
            cart: cart
        });
        this.props.logEvent('hotel', 'addItem', item.label);
    }


    removeItem(item) {
        var cart = this.state.cart;
        var cart_new = cart.filter(i => i !== item);
        this.setState({
            cart: cart_new
        });
        this.props.logEvent('hotel', 'removeItem', item.label);
    }


    addWaitlist(item) {
        this.removeWaitlist(item);
        var waitlist = this.state.waitlist;
        waitlist.push(item);
        this.setState({
            waitlist: waitlist
        });
        this.props.logEvent('hotel', 'addWaitlist', item.label);
    }


    removeWaitlist(item) {
        var waitlist = this.state.waitlist;
        var waitlist_new = waitlist.filter(i => i !== item);
        this.setState({
            waitlist: waitlist_new
        });
        this.props.logEvent('hotel', 'removeWaitlist', item.label);
    }


    setCheckout(state) {
        this.setState({
            checkout: state
        });
    }


    submitRequest(date_from, date_to, room, name, email) {
        const cart = this.state.cart;
        const waitlist = this.state.waitlist;
        this.setState({
            cart: [],
            waitlist: []
        });
        this.props.submitRentRequest(cart, waitlist, date_from, date_to, room, name, email);
        this.props.logEvent('hotel', 'submitRequest', '');
    }


    render() {

        return (
            <div className="shop-container">
                <div className="shop-wrapper">

                    {this.state.checkout &&
                        <Checkout
                            cart={this.state.cart}
                            waitlist={this.state.waitlist}
                            setCheckout={this.setCheckout}
                            removeItem={this.removeItem}
                            submitRequest={this.submitRequest}
                            logEvent={this.props.logEvent}
                            setLoading={this.props.setLoading}
                            unsetLoading={this.props.unsetLoading}
                        />
                    }

                    <div className="shop__categories">
                        <div className="shop__categories__section">
                            <div className="shop__categories__title">
                                Category:
                            </div>
                            <div onClick={() => this.setCategory("")} className={"shop__categories__element" + (this.state.filter_category === "" ? " shop__categories__element--selected" : "")}>
                                All
                            </div>
                            {this.state.categories.map((cat, i) =>
                                <div key={i} onClick={() => this.setCategory(cat)} className={"shop__categories__element" + (this.state.filter_category === cat ? " shop__categories__element--selected" : "")}>
                                    {cat}
                                </div>
                            )}
                        </div>
                        {/* <div className="shop__categories__section">
                            <div className="shop__categories__title">
                                Type:
                            </div>
                            <div onClick={() => this.setType("")} className={"shop__categories__element" + (this.state.filter_type === "" ? " shop__categories__element--selected" : "")}>
                                All
                            </div>
                            <div onClick={() => this.setType("complimentary")} className={"shop__categories__element" + (this.state.filter_type === "complimentary" ? " shop__categories__element--selected" : "")}>
                                Complimentary
                            </div>
                            <div onClick={() => this.setType("rent")} className={"shop__categories__element" + (this.state.filter_type === "rent" ? " shop__categories__element--selected" : "")}>
                                For rent
                            </div>
                        </div> */}
                        <div className="shop__categories__section">
                            <div className="shop__categories__title">
                                Availability:
                            </div>
                            <div onClick={() => this.setAvailability("")} className={"shop__categories__element" + (this.state.filter_available === "" ? " shop__categories__element--selected" : "")}>
                                All
                            </div>
                            <div onClick={() => this.setAvailability(0)} className={"shop__categories__element" + (this.state.filter_available === 0 ? " shop__categories__element--selected" : "")}>
                                Available today
                            </div>
                            <div onClick={() => this.setAvailability(1)} className={"shop__categories__element" + (this.state.filter_available === 1 ? " shop__categories__element--selected" : "")}>
                                Available tomorrow
                            </div>
                            <div onClick={() => this.setAvailability(2)} className={"shop__categories__element" + (this.state.filter_available === 2 ? " shop__categories__element--selected" : "")}>
                                Available in 2 days
                            </div>
                        </div>
                    </div>

                    <div className="shop__catalogue">
                        {this.state.items_filtered.map(item =>
                            <Item
                                key={item.id}
                                data={item}
                                selected={(this.state.cart.filter(i => i === item)).length >= 1 ? true : false}
                                waitlist={(this.state.waitlist.filter(i => i === item)).length >= 1 ? true : false}
                                addItem={this.addItem}
                                removeItem={this.removeItem}
                                addWaitlist={this.addWaitlist}
                                removeWaitlist={this.removeWaitlist}
                            />
                        )}
                    </div>

                    {this.state.cart.length >= 1 &&
                        <div className="shop__checkout-bar-container">
                            <div className="shop__checkout-bar-wrapper">
                                <div className="shop__checkout-bar__summary">
                                    <div className="shop__checkout-bar__summary__element text-bold">
                                        {this.state.cart.length} items selected
                                    </div>
                                    <div className="shop__checkout-bar__summary__element">
                                        Please go to checkout to finalize your order
                                    </div>
                                </div>
                                <div className="shop__checkout-bar__actionbox">
                                    <div onClick={() => this.setCheckout(true)} className="shop__checkout-bar__actionbox__element button button-primary-light">
                                        Checkout
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import Item from './item.js';
import Checkout from './checkout.js';


export default class Wishlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            filter_category: "",
            categories: [],
            items: this.props.items,
            items_filtered: [],
            wishlist: []
        };

        this.getCategories = this.getCategories.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.filterItems = this.filterItems.bind(this);
        this.addItem = this.addItem.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
    }


    componentDidMount() {
        this.setState({
            items: this.props.items,
            items_filtered: this.props.items
        });
        this.getCategories();
    }


    componentDidUpdate() {
        if (this.props.items !== this.state.items) {
            this.setState({
                items: this.props.items,
                items_filtered: this.props.items
            });
            this.getCategories();
        }
    }


    getCategories() {
        var list = this.props.items;
        var categories = [...new Set(list.map(item => item.category))];
        this.setState({
            categories: categories
        });
    }


    async setCategory(category) {
        await this.setState({
            filter_category: category
        });
        await this.filterItems();
        this.props.logEvent('hotel', 'setCategory', category);
    }


    async filterItems() {
        var list_filtered = this.state.items;
        if (this.state.filter_category !== "") {
            list_filtered = this.state.items.filter(item => item.category === this.state.filter_category);
        }
        this.setState({
            items_filtered: list_filtered
        });
    }


    addItem(item) {
        this.removeItem(item);
        var wishlist = this.state.wishlist;
        wishlist.push(item);
        this.setState({
            wishlist: wishlist
        });
        this.props.logEvent('hotel', 'addItemToWishlist', item.label);
    }


    removeItem(item) {
        var wishlist = this.state.wishlist;
        var wishlist_new = wishlist.filter(i => i !== item);
        this.setState({
            wishlist: wishlist_new
        });
        this.props.logEvent('hotel', 'removeItemFromWishlist', item.label);
    }


    submitRequest() {
        const wishlist = this.state.wishlist;
        this.setState({
            wishlist: []
        });
        this.props.submitWishlistRequest(wishlist);
        this.props.logEvent('hotel', 'submitWaitlistRequest', '');
    }


    render() {

        return (
            <div className="shop-container">
                <div className="shop__header">
                    <div className="shop__header__element">
                        Your order has been <span className="text-bold text-color-primary">received and will be delivered to your room</span> soon.
                    </div>
                    <div className="shop__header__element">
                        Below is a list of items that the hotel currently doesn't offer for rent.
                        Please indicate your interest in renting out any of these items in the future.
                    </div>
                    <div className="shop__header__element">
                        The hotel appreciates your help to improve their rental offering. 
                    </div>
                </div>
                <div className="shop-wrapper">
                    <div className="shop__categories">
                        <div className="shop__categories__section">
                            <div className="shop__categories__title">
                                Category:
                            </div>
                            <div onClick={() => this.setCategory("")} className={"shop__categories__element" + (this.state.filter_category === "" ? " shop__categories__element--selected" : "")}>
                                All
                            </div>
                            {this.state.categories.map((cat, i) =>
                                <div key={i} onClick={() => this.setCategory(cat)} className={"shop__categories__element" + (this.state.filter_category === cat ? " shop__categories__element--selected" : "")}>
                                    {cat}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="shop__catalogue">
                        {this.state.items_filtered.map(item =>
                            <Item
                                key={item.id}
                                data={item}
                                selected={(this.state.wishlist.filter(i => i === item)).length >= 1 ? true : false}
                                waitlist={false}
                                addItem={this.addItem}
                                removeItem={this.removeItem}
                            />
                        )}
                    </div>

                    {this.state.wishlist.length >= 1 &&
                        <div className="shop__checkout-bar-container shop__checkout-bar-container--wishlist">
                            <div className="shop__checkout-bar-wrapper">
                                <div className="shop__checkout-bar__summary">
                                    <div className="shop__checkout-bar__summary__element text-bold">
                                        {this.state.wishlist.length} items selected
                                    </div>
                                    <div className="shop__checkout-bar__summary__element">
                                        Please click submit to indicate your interest in additional items
                                    </div>
                                </div>
                                <div className="shop__checkout-bar__actionbox">
                                    <div onClick={() => this.submitRequest()} className="shop__checkout-bar__actionbox__element button button-secondary-light">
                                        Submit
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div className="footer-wrapper">

                    <div className="footer__column">
                        <div className="footer__column__element footer__column__title text-bold text-title">
                            About
                        </div>
                        <div className="footer__column__element text-box text-justify">
                            CirQle is a fully automated software platform that allows hotels to rent out items to their guests.
                        </div>
                        <div className="footer__column__element text-box text-justify">
                            By renting out items systematically, hotels can distinguish themselves and provide their guests the best possible experience.
                        </div>
                    </div>

                    <div className="footer__column">
                        <div className="footer__column__element footer__column__title text-bold text-title">
                            Explore our offering
                        </div>
                        <div className="footer__column__element">
                            <a href="/#benefits" className="link-color-light">
                                Why CirQle
                            </a>
                        </div>
                        <div className="footer__column__element">
                            <a href="/#features" className="link-color-light">
                                Key features
                            </a>
                        </div>
                        <div className="footer__column__element">
                            <a href="/#working" className="link-color-light">
                                How it works
                            </a>
                        </div>
                        {/* <div className="footer__column__element">
                            <Link to="/getstarted" className="link-color-light">
                                Interest survey
                            </Link>
                        </div> */}
                    </div>

                    <div className="footer__column">
                        <div className="footer__column__element footer__column__title text-bold text-title">
                            Contact
                        </div>
                        <div className="footer__column__element">
                            <Link to="/contact" className="link-color-light">
                                Get in touch
                            </Link>
                        </div>
                        <div className="footer__column__element">
                            <Link to="/demo" className="link-color-light">
                                Request a demo
                            </Link>
                        </div>

                        <div className="footer__column__element footer__column__title text-bold text-title">
                            Social media
                        </div>
                        <div className="footer__column__element">
                            Coming soon
                        </div>
                        {/* <div className="footer__column__element footer-social-media-container">
                            <Link to="#">
                                Facebook
                                {<IconFacebook className="footer-social-media-logo"/>}
                            </Link>
                            <Link to="#">
                                Instagram
                                {<IconInstagram className="footer-social-media-logo"/>}
                            </Link>
                        </div> */}
                    </div>

                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import ForminputText from '../forminput/forminput_text.js';
import ForminputTextbox from '../forminput/forminput_textbox.js';
import axios from 'axios';


export default class Demo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            name: "",
            email: "",
            message: ""
        };

        this.baseUrl = this.props.baseUrl;
        this.setFormValue = this.setFormValue.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.submit = this.submit.bind(this);
    }


    setFormValue(label, value) {
        // Update state
        this.setState({
            [label]: value
        });
    }


    validateForm() {
        // Check name value
        if (!this.state.name) {
            alert("Please include your name");
            return false
        }
        // Check email value
        if (!this.state.email) {
            alert("Please include your email");
            return false
        }
        // Check message value
        if (!this.state.message) {
            alert("Please include your message");
            return false
        }
        return true;
    }


    async submit() {
        this.props.logEvent('contact', 'request', this.state.email);
        // Submit to server
        try {
            if (this.validateForm()) {
                await axios.post(this.baseUrl + "/api/v001/submitcontactrequest", {
                    name: this.state.name,
                    email: this.state.email,
                    message: this.state.message
                })
                    .then(res => {
                        var data = res.data;
                        if (data) {
                            alert("Thank you - we will be in touch with you as soon as possible.");
                        } else {
                            // Track error
                            // TO DO
                        }
                    })
                    .catch(err => {
                        // Track error
                        // TO DO
                    });
                // Reset component
                this.setState({
                    name: "",
                    email: "",
                    message: ""
                });
                this.props.history.push('/');
                window.scrollTo(0, 0);
            }
        } catch (error) {
            // Track error
            // TO DO
        }
    }


    render() {

        return (
            <div className="content-container">
                <div className="content-wrapper">
                    <div className="contact__title text-title text-color-primary">
                        Request a product demo
                    </div>
                    <div className="contact__section">
                        <div className="form-container">
                            <div className="form__content form__content--no-padding">

                                <div className="form__content__element form__content__element--double-columns form__content__input">
                                    {<ForminputText
                                        name={"name"}
                                        label={"Your name"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your name so we know who you are."}
                                    />}
                                    {<ForminputText
                                        name={"email"}
                                        label={"Your e-mail"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your email so we can respond to you."}
                                    />}
                                </div>

                                <div className="form__content__element form__content__input">
                                    {<ForminputTextbox
                                        name={"message"}
                                        label={"Your message"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your message to us."}
                                    />}
                                </div>

                                <div className="form__content__element">
                                    We will get back to you via e-mail as soon as we can to schedule your product demo. 
                                </div>

                                <div className="form__content__element form__content__buttonbox">
                                    <div onClick={this.submit} className="form__content__buttonbox__button button button-primary">
                                        Submit
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';

export default class ForminputText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            input: "",
            input_value: "",
            input_active: false
        };

        this.inputField = React.createRef();

        this.clickInput = this.clickInput.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.blurInput = this.blurInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.showPopupNote = this.showPopupNote.bind(this);
    }


    componentDidMount() {
    }


    clickInput() {
        this.inputField.current.focus();
    }


    focusInput() {
        this.setState({
            input_active: true
        });
    }


    blurInput() {
        this.setState({
            input_active: false
        });
    }


    handleInputChange(event) {
        this.setState({
            input: event.target.value
        });
        this.props.setFormValue(this.props.name, event.target.value);
    }


    showPopupNote() {
        alert(this.props.note);
    }


    render() {
        return (
            <div className={"form__input-container" + (this.props.halfwidth ? " form__input-container--half" : "")}>


                {this.props.label &&
                    <div className="form__input__headline">
                        <div className="form__input__headline__label">
                            {this.props.label}
                        </div>
                        <div className="form__input__headline__infobox">
                            {this.props.required &&
                                <div className="form__input__headline__infobox__icon form__input__headline__infobox__icon--required"></div>
                            }
                            <div onClick={this.showPopupNote} className="form__input__headline__infobox__icon"></div>
                        </div>
                    </div>
                }
                <div onClick={this.clickInput} className={"form__input__textbox" + (this.state.input_active ? " form__input__textbox--active" : "")}>
                    <textarea ref={this.inputField} onFocus={this.focusInput} onBlur={this.blurInput} onChange={this.handleInputChange} id={"input_" + this.props.name} name={"input_" + this.props.name} value={this.state.input && this.state.input !== "" ? this.state.input : ""} placeholder="Please type" className="form__input__textbox__input" />
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { ReactComponent as IconArrow } from '../../media/icons/arrow_right.svg';
import illustration_working_1 from '../../media/images/home/working_1.png';
import illustration_working_2 from '../../media/images/home/working_2.png';
import illustration_working_3 from '../../media/images/home/working_3.png';
import illustration_working_4 from '../../media/images/home/working_4.png';
import illustration_working_5 from '../../media/images/home/working_5.png';
import illustration_working_6 from '../../media/images/home/working_6.png';


export default class Working extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {

        return (
            <div id="working" className="home__section">
                <div className="home__section__title text-title text-color-primary">
                    How it works
                </div>
                <div className="home__working">

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_1} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            1. Provide a QR code to guests
                        </div>
                        <div className="home__working__step__description">
                            Guests can access your shop system using a QR code or url. We recommend a printed QR code upon check-in at the reception or in the room. 
                        </div>
                    </div>

                    <div className="home__working__separator">
                        <IconArrow className="home__working__separator__arrow" />
                    </div>

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_2} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            2. Guest requests items
                        </div>
                        <div className="home__working__step__description">
                            Your guest scans the QR code to access your shop system. There, your guest selects items and submits their request. 
                        </div>
                    </div>

                    <div className="home__working__separator">
                        <IconArrow className="home__working__separator__arrow" />
                    </div>

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_3} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            3. Receive an order request
                        </div>
                        <div className="home__working__step__description">
                            You will receive your guest's order request immediately and get notified via email as well as on our platform. 
                        </div>
                    </div>

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_4} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            4. Fulfill the order
                        </div>
                        <div className="home__working__step__description">
                            You can let your guest pick up their items or bring them to their room. 
                        </div>
                    </div>

                    <div className="home__working__separator">
                        <IconArrow className="home__working__separator__arrow" />
                    </div>

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_5} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            5. Your guest returns the items
                        </div>
                        <div className="home__working__step__description">
                            Upon checkout or before that your guest returns all the rented items or leaves them in the room. 
                        </div>
                    </div>

                    <div className="home__working__separator">
                        <IconArrow className="home__working__separator__arrow" />
                    </div>

                    <div className="home__working__step">
                        <div className="home__working__step__illustration-wrapper">
                            <img src={illustration_working_6} className="home__working__step__illustration" />
                            <div className="home__working__step__illustration__shadow"></div>
                        </div>
                        <div className="home__working__step__label text-subtitle text-bold text-color-primary">
                            6. Prepare for the next order
                        </div>
                        <div className="home__working__step__description">
                            The returned items are made available for other guests to order. 
                        </div>
                    </div>




                </div>

            </div>
        )
    }
}

import React, { Component } from 'react';


export default class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {

        return (
            <div className="shop-container">
                <div className="shop__header">
                    <div className="shop__header__element">
                        Your order has been <span className="text-bold text-color-primary">received and will be delivered to your room</span> soon.
                    </div>
                    <div className="shop__header__element">
                        Below is a list of items that the hotel currently doesn't offer for rent.
                        Please indicate your interest in renting out any of these items in the future.
                    </div>
                    <div className="shop__header__element">
                        The hotel appreciates your help to improve their rental offering.
                    </div>
                </div>
            </div>
        )
    }
}

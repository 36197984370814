import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';

import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import Loading from './components/loading/loading.js';
import Error from './components/banners/error.js';
import Home from './components/home/home.js';
import Contact from './components/contact/contact.js';
import Demo from './components/contact/demo.js';
import Getstarted from './components/getstarted/getstarted.js';
import Hotel from './components/hotel/hotel.js';
import Dashboard from './components/dashboard/dashboard.js';

ReactGA.initialize('G-TB9NF18KBC');


export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Default states
      loading: false,
      error: false
    };

    this.baseUrl = "https://api.mycirqle.com";

    /*         this.cookies = new Cookies();
        this.getCitiesData = this.getCitiesData.bind(this); */

    this.logEvent = this.logEvent.bind(this);
    this.setLoading = this.setLoading.bind(this);
    this.unsetLoading = this.unsetLoading.bind(this);
    this.setError = this.setError.bind(this);
    this.unsetError = this.unsetError.bind(this);
  }


  componentDidMount() {
    /* ReactGA.pageview(window.location.pathname + window.location.search); */
  }


  logEvent(category, action, label) {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  }


  setLoading() {
    this.setState({
      loading: true
    });
  }


  unsetLoading() {
    this.setState({
      loading: false
    });
  }


  setError(name, message) {
    var error = {
      name: name,
      message: message
    }
    this.setState({
      error: error
    });
  }


  unsetError() {
    this.setState({
      error: false,
      loading: false
    });
  }


  render() {
    return (
      <div>

        <Header
        />

        {this.state.loading &&
          <Loading
          />
        }

        {this.state.error &&
          <Error
            error={this.state.error}
            closeError={this.unsetError}
          />
        }


        <Route
          exact={true}
          path='/'
          render={
            (props) => (
              <Home
                {...props}
              />)
          }
        />


        <Route
          exact={true}
          path='/contact'
          render={
            (props) => (
              <Contact
                logEvent={this.logEvent}
                baseUrl={this.baseUrl}
                {...props}
              />)
          }
        />


        <Route
          exact={true}
          path='/demo'
          render={
            (props) => (
              <Demo
                logEvent={this.logEvent}
                baseUrl={this.baseUrl}
                {...props}
              />)
          }
        />


        <Route
          exact={true}
          path='/getstarted'
          render={
            (props) => (
              <Home
                {...props}
              />)
          }
        />


        <Route
          exact={true}
          path='/getstarted'
          render={
            (props) => (
              <Getstarted
                baseUrl={this.baseUrl}
                logEvent={this.logEvent}
                {...props}
              />)
          }
        />


        <Route
          exact={false}
          path='/hotel/:hotelid?'
          render={
            (props) => (
              <Hotel
                baseUrl={this.baseUrl}
                logEvent={this.logEvent}
                setLoading={this.setLoading}
                unsetLoading={this.unsetLoading}
                setError={this.setError}
                {...props}
              />)
          }
        />


        <Route
          exact={false}
          path='/dashboard/:hotelid?'
          render={
            (props) => (
              <Dashboard
                baseUrl={this.baseUrl}
                logEvent={this.logEvent}
                setLoading={this.setLoading}
                unsetLoading={this.unsetLoading}
                setError={this.setError}
                {...props}
              />)
          }
        />


        <Footer
        />

      </div>
    );
  }
}

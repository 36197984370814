import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Hero from './hero.js';
import Features from './features.js';
import Benefits from './benefits.js';
import Working from './working.js';
import Testimonials from './testimonials.js';
import Contact from './contact.js';
import References from './references.js';


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };

    }


    render() {

        return (
            <div className="content-container">

                {<Hero />}

                <div className="content-wrapper">
                    {<Benefits />}
                    {<Features />}
                    {<Working />}
                    {<Testimonials />}
                </div>
                    {<References />}
                <div className="content-wrapper">
                    {<Contact />}
                </div>
                
            </div>
        )
    }
}

import React, { Component } from 'react';
import screen_items from '../../media/images/home/screen_items.png';
import screen_rentals from '../../media/images/home/screen_rentals.png';
import screen_inventory from '../../media/images/home/screen_inventory.png';


export default class Features extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            page: 1
        };

        this.setPage = this.setPage.bind(this);
    }


    setPage(page) {
        this.setState({
            page: page
          });
    }


    render() {

        return (
            <div id="features" className="home__section">
                <div className="home__section__title text-title text-color-primary">
                    All you need to rent out items to your guests
                </div>

                <div className="home__features-container">
                    <div className="home__features__navbar">
                        <div onClick={() => this.setPage(1)} className={"text-subtitle home__features__navbar__element" + (this.state.page === 1 ? " home__features__navbar__element--selected" : "")}>
                            Item overview
                        </div>
                        <div onClick={() => this.setPage(2)} className={"text-subtitle home__features__navbar__element" + (this.state.page === 2 ? " home__features__navbar__element--selected" : "")}>
                            Order management
                        </div>
                        <div onClick={() => this.setPage(3)} className={"text-subtitle home__features__navbar__element" + (this.state.page === 3 ? " home__features__navbar__element--selected" : "")}>
                            Inventory tracking
                        </div>
                    </div>

                    <div className={"home__features__content-container" + (this.state.page === 1 ? " home__features__content-container--selected" : "")}>
                        <div className="home__features__content__screen">
                            <img src={screen_items} className="home__features__content__screen__inner" />
                        </div>
                        <div className="home__features__content__description">
                            <div className="home__features__content__description__bullet">
                                Guests can choose available items from the hotel's individual shop system. 
                            </div>
                            <div className="home__features__content__description__bullet">
                                Items can be either rented out or offered as a complimentary service. 
                            </div>
                            <div className="home__features__content__description__bullet">
                                Once the guest submitted their order, relevant hotel staff are notified. 
                            </div>
                        </div>
                    </div>

                    <div className={"home__features__content-container" + (this.state.page === 2 ? " home__features__content-container--selected" : "")}>
                        <div className="home__features__content__screen">
                            <img src={screen_rentals} className="home__features__content__screen__inner" />
                        </div>
                        <div className="home__features__content__description">
                            <div className="home__features__content__description__bullet">
                                Receive and manage orders in real-time to provide the best possible service to your guests.
                            </div>
                            <div className="home__features__content__description__bullet">
                                View guest orders in a dashboard to keep track of past, ongoing, and upcoming orders. 
                            </div>
                            <div className="home__features__content__description__bullet">
                                Receive email notifications to never miss new order requests. 
                            </div>
                        </div>
                    </div>

                    <div className={"home__features__content-container" + (this.state.page === 3 ? " home__features__content-container--selected" : "")}>
                        <div className="home__features__content__screen">
                            <img src={screen_inventory} className="home__features__content__screen__inner" />
                        </div>
                        <div className="home__features__content__description">
                            <div className="home__features__content__description__bullet">
                                Manage your inventory to always know where your items are and never run out of stock. 
                            </div>
                            <div className="home__features__content__description__bullet">
                                Understand your guests' demand and always have the right items in stock. 
                            </div>
                            <div className="home__features__content__description__bullet">
                                Rest assured as your individual shop system is always up to date and synchronized to your inventory. 
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { ReactComponent as IconArrow } from '../../media/icons/arrow_right.svg';


export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            page: 1
        };
        
        this.minPage = 1;
        this.maxPage = 2;

        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
    }


    previousPage() {
        var pageNew = this.state.page - 1;
        if(pageNew < this.minPage) {
            pageNew = this.maxPage;
        }
        this.setState({
            page: pageNew
        });
    }


    nextPage() {
        var pageNew = this.state.page + 1;
        if(pageNew > this.maxPage) {
            pageNew = this.minPage;
        }
        this.setState({
            page: pageNew
        });
    }


    render() {

        return (
            <div className="home__section">
                {/* <div className="home__section__title text-title text-color-primary">
                    Hear from our customers
                </div> */}
                <div className="home__testimonials-container">
                    <IconArrow onClick={this.previousPage} className="home__testimonials__button home__testimonials__button__previous" />

                    {/* <div className={"home__testimonials__testimonial" + (this.state.page === 1 ? "" : " home__testimonials__testimonial--hidden")}>
                        <div className="home__testimonials__testimonial__picture-container">
                            <div className="home__testimonials__testimonial__picture home__testimonials__testimonial__picture--jamar"></div>
                            <div className="home__testimonials__testimonial__name">
                                Jamar
                            </div>
                            <div className="home__testimonials__testimonial__title text-bold text-color-primary">
                                General Manager Hotel
                            </div>
                        </div>
                        <div className="home__testimonials__testimonial__quote-container">
                            <div className="home__testimonials__testimonial__icon"></div>
                            <div className="home__testimonials__testimonial__quote text-subtitle">
                                <p>
                                    CirQle has been a true game changer for my hotel - guests love the option to rent out items and staff is happy with the automation the software provides. 
                                </p>
                            </div>
                        </div>
                    </div> */}

                    <div className={"home__testimonials__testimonial" + (this.state.page === 1 ? "" : " home__testimonials__testimonial--hidden")}>
                        <div className="home__testimonials__testimonial__picture-container">
                            <div className="home__testimonials__testimonial__picture home__testimonials__testimonial__picture--janice"></div>
                            <div className="home__testimonials__testimonial__name">
                                Janice
                            </div>
                            <div className="home__testimonials__testimonial__title text-bold text-color-primary">
                                Founder CirQLe
                            </div>
                        </div>
                        <div className="home__testimonials__testimonial__quote-container">
                            <div className="home__testimonials__testimonial__icon"></div>
                            <div className="home__testimonials__testimonial__quote text-subtitle">
                                <p>
                                    I founded CirQle out of my own necessity to order items while staying at a hotel. Sometimes I would forget things and sometimes I just want to pack light. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={"home__testimonials__testimonial" + (this.state.page === 2 ? "" : " home__testimonials__testimonial--hidden")}>
                        <div className="home__testimonials__testimonial__picture-container">
                            <div className="home__testimonials__testimonial__picture home__testimonials__testimonial__picture--oskar"></div>
                            <div className="home__testimonials__testimonial__name">
                                Oskar
                            </div>
                            <div className="home__testimonials__testimonial__title text-bold text-color-primary">
                                Consultant and regular hotel guest
                            </div>
                        </div>
                        <div className="home__testimonials__testimonial__quote-container">
                            <div className="home__testimonials__testimonial__icon"></div>
                            <div className="home__testimonials__testimonial__quote text-subtitle">
                                <p>
                                    I travel for work and stay in hotels often. I exclusively book hotels that allow me to rent equipment to work from the room easily, like a keyboard and monitor. 
                                </p>
                            </div>
                        </div>
                    </div>

                    <IconArrow onClick={this.nextPage} className="home__testimonials__button home__testimonials__button__next" />
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            menu_dropdown: false
        };

        this.toggleMenu = this.toggleMenu.bind(this);
    }


    toggleMenu() {
        this.setState({
            menu_dropdown: !this.state.menu_dropdown
        });
    }


    render() {
        return (
            <div className="header">
                <div className="header-wrapper">
                    <Link to="/" className="header__logo"></Link>

                    <div onClick={this.toggleMenu} className="header__menu">
                        <div className={"header__menu__bar header__menu__bar-1" + (this.state.menu_dropdown ? " header__menu__bar-1--active" : "")}></div>
                        <div className={"header__menu__bar header__menu__bar-2" + (this.state.menu_dropdown ? " header__menu__bar-2--active" : "")}></div>
                        <div className={"header__menu__bar header__menu__bar-3" + (this.state.menu_dropdown ? " header__menu__bar-3--active" : "")}></div>
                    </div>

                    <div className={"header__navbar" + (this.state.menu_dropdown ? " header__navbar--active" : "")}>
                        <div className="header__navbar__element">
                            {/* <Link to="/getstarted" className="header__navbar__link">
                                Get Started
                            </Link> */}
                            <Link to="/contact" className="header__navbar__link">
                                Get in touch
                            </Link>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

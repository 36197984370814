import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };

    }


    render() {

        return (
            <div className="home__section">
                <div className="home__section__title text-title text-color-primary">
                    Start offering your guests the best possible experience
                </div>
                <div className="home__section__buttonbox">
                    <Link to={'/contact'} className="button button--subtitle button-primary text-subtitle">
                        Get in touch
                    </Link>
                </div>
            </div>
        )
    }
}

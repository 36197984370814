import React, { Component } from 'react';
import { ReactComponent as IconClose } from '../../media/icons/close.svg';


export default class Errorbanner extends Component {
    constructor(props) {
      super(props);
      this.state = {
        // Default states
      };
      this.closeError = this.closeError.bind(this);
    }


    closeError() {
        this.props.closeError();
    }


    render() {
        return (
            <div className="banner">
                <div className="banner__notification text-color-light">
                    {this.props.error.name}
                    {": "}
                    {this.props.error.message}
                </div>
                <IconClose onClick={this.closeError} className="banner__close" />
            </div>
        )
    }
}

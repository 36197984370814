import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconClosePopup } from '../../media/icons/popup_close.svg';
import ForminputText from '../forminput/forminput_text.js';
import axios from 'axios';


export default class Getstarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            mode: "",
            category: "",
            items: [],
            lastStep: false,
            email: "",
            date_value: Date.now()
        };

        this.baseUrl = this.props.baseUrl;
        this.setFormValue = this.setFormValue.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.setMode = this.setMode.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.setItem = this.setItem.bind(this);
        this.resetItems = this.resetItems.bind(this);
        this.setLastStep = this.setLastStep.bind(this);
        this.submit = this.submit.bind(this);
    }


    setFormValue(label, value) {
        // Update state
        this.setState({
            email: value
        });
    }


    closePopup() {
        this.props.logEvent('getstarted', 'closed', '');
        this.props.history.push('/');
    }


    setMode(mode) {
        this.setState({
            mode: mode
        });
        this.props.logEvent('getstarted', 'mode', mode);
    }


    setCategory(category) {
        this.setState({
            category: category
        });
        this.props.logEvent('getstarted', 'category', category);
    }


    setItem(item) {
        if (!this.state.items.includes(item)) {
            var itemlist = this.state.items;
            itemlist.push(item);
            this.setState({
                items: itemlist
            });
            this.props.logEvent('getstarted', 'item', item);
        }
    }


    resetItems() {
        this.setState({
            items: []
        });
    }


    async setLastStep(value) {
        // Prepare data
        var data_survey = {
            mode: this.state.mode,
            category: this.state.category,
            items: this.state.items
        }
        // Submit to server
        try {
            await axios.post(this.baseUrl + "/api/v001/submitgetstarted", {
                data: data_survey,
                id: this.state.date_value
            })
            .then(res => {
                var data = res.data;
                if (data) {
                } else {
                    // Track error
                    // TO DO
                }
            })
            .catch(err => {
                // Track error
                // TO DO
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            // TO DO
        }
        // Update state
        this.setState({
            lastStep: value
        });
    }


    async submit() {
        this.props.logEvent('getstarted', 'email', this.state.email);
        // Prepare data
        var data_survey = {
            mode: this.state.mode,
            category: this.state.category,
            items: this.state.items,
            email: this.state.email
        }
        // Submit to server
        try {
            await axios.post(this.baseUrl + "/api/v001/submitgetstarted", {
                data: data_survey,
                id: this.state.date_value
            })
            .then(res => {
                var data = res.data;
                if (data) {
                    alert("Thank you - we will be in touch with you once we start our offering.");
                } else {
                    // Track error
                    // TO DO
                }
            })
            .catch(err => {
                // Track error
                // TO DO
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            // TO DO
        }
        // Reset component
        this.setState({
            mode: "",
            category: "",
            items: [],
            lastStep: false,
            email: ""
        });
        this.props.history.push('/');
    }


    render() {

        return (
            <div className="overlay">

                {!this.state.mode &&
                    <div className="overlay__popup">
                        <div className="form-container">
                            <div className="form__header">
                                <div className="form__header__title">
                                    What are you traveling for?
                                </div>
                                <IconClosePopup onClick={this.closePopup} className="form__header__close" />
                            </div>
                            <div className="form__content">
                                <div className="form__content__element form__content__optionbox">
                                    <div onClick={() => this.setMode("business")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--business"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Traveling for business
                                        </div>
                                    </div>
                                    <div onClick={() => this.setMode("leisure")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--leisure"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Traveling for leisure
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.mode && !this.state.category &&
                    <div className="overlay__popup">
                        <div className="form-container">
                            <div className="form__header">
                                <div className="form__header__title">
                                    What items are you most interested in to rent?
                                </div>
                                <IconClosePopup onClick={this.closePopup} className="form__header__close" />
                            </div>
                            <div className="form__content">
                                <div className="form__content__element form__content__optionbox form__content__optionbox--four">

                                    <div onClick={() => this.setCategory("business_essentials")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--business_essentials"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Business essentials
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("electronics")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--electronics"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Electronics
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("personal_care")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--personal_care"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Personal care
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("hair_care")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--hair_care"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Hair care
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("clothing")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--clothing"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Clothing
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("fitness")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--fitness"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Fitness
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("entertainment")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--entertainment"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Entertainment
                                        </div>
                                    </div>

                                    <div onClick={() => this.setCategory("other")} className="form__content__optionbox__option">
                                        <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--other"></div>
                                        <div className="form__content__optionbox__option__label">
                                            Other
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                {this.state.mode && this.state.category && !this.state.lastStep &&
                    <div className="overlay__popup">
                        <div className="form-container">
                            <div className="form__header">
                                <div className="form__header__title">
                                    What items do you want to rent?
                                </div>
                                <IconClosePopup onClick={this.closePopup} className="form__header__close" />
                            </div>
                            <div className="form__content">
                                <div className="form__content__element form__content__optionbox form__content__optionbox--four">

                                    {this.state.category === "business_essentials" &&
                                        <div onClick={() => this.setItem("extension_monitor")} className={"form__content__optionbox__option" + (this.state.items.includes("extension_monitor") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--extension_monitor"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Extension monitor
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "business_essentials" &&
                                        <div onClick={() => this.setItem("mouse")} className={"form__content__optionbox__option" + (this.state.items.includes("mouse") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--mouse"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Mouse
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "business_essentials" &&
                                        <div onClick={() => this.setItem("keyboard")} className={"form__content__optionbox__option" + (this.state.items.includes("keyboard") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--keyboard"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Keyboard
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "business_essentials" &&
                                        <div onClick={() => this.setItem("projector")} className={"form__content__optionbox__option" + (this.state.items.includes("projector") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--projector"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Projector
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "business_essentials" &&
                                        <div onClick={() => this.setItem("printer")} className={"form__content__optionbox__option" + (this.state.items.includes("printer") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--printer"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Printer
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "electronics" &&
                                        <div onClick={() => this.setItem("charger")} className={"form__content__optionbox__option" + (this.state.items.includes("charger") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--charger"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Charger
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "electronics" &&
                                        <div onClick={() => this.setItem("headphones")} className={"form__content__optionbox__option" + (this.state.items.includes("headphones") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--headphones"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Headphones
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "electronics" &&
                                        <div onClick={() => this.setItem("international_adapters")} className={"form__content__optionbox__option" + (this.state.items.includes("international_adapters") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--international_adapters"></div>
                                            <div className="form__content__optionbox__option__label">
                                                International adapters
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "electronics" &&
                                        <div onClick={() => this.setItem("portable_wifi")} className={"form__content__optionbox__option" + (this.state.items.includes("portable_wifi") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--portable_wifi"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Portable WiFi
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "personal_care" &&
                                        <div onClick={() => this.setItem("skin_care")} className={"form__content__optionbox__option" + (this.state.items.includes("skin_care") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--skin_care"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Skin care
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "personal_care" &&
                                        <div onClick={() => this.setItem("toiletries")} className={"form__content__optionbox__option" + (this.state.items.includes("toiletries") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--toiletries"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Toiletries
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "hair_care" &&
                                        <div onClick={() => this.setItem("premium_hairdryer")} className={"form__content__optionbox__option" + (this.state.items.includes("premium_hairdryer") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--premium_hairdryer"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Premium hairdryer
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "hair_care" &&
                                        <div onClick={() => this.setItem("straightener")} className={"form__content__optionbox__option" + (this.state.items.includes("straightener") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--straightener"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Straightener
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "hair_care" &&
                                        <div onClick={() => this.setItem("curler")} className={"form__content__optionbox__option" + (this.state.items.includes("curler") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--curler"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Curler
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "clothing" &&
                                        <div onClick={() => this.setItem("dresses")} className={"form__content__optionbox__option" + (this.state.items.includes("dresses") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--dresses"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Dresses
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "clothing" &&
                                        <div onClick={() => this.setItem("accessories")} className={"form__content__optionbox__option" + (this.state.items.includes("accessories") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--accessories"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Accessories
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "clothing" &&
                                        <div onClick={() => this.setItem("handbags")} className={"form__content__optionbox__option" + (this.state.items.includes("handbags") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--handbags"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Handbags
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "clothing" &&
                                        <div onClick={() => this.setItem("suits")} className={"form__content__optionbox__option" + (this.state.items.includes("suits") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--suits"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Suits for special occasions
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "fitness" &&
                                        <div onClick={() => this.setItem("yoga_mat")} className={"form__content__optionbox__option" + (this.state.items.includes("yoga_mat") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--yoga_mat"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Yoga mats
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "fitness" &&
                                        <div onClick={() => this.setItem("weights")} className={"form__content__optionbox__option" + (this.state.items.includes("weights") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--weights"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Weights
                                            </div>
                                        </div>
                                    }

                                    {this.state.category === "entertainment" &&
                                        <div onClick={() => this.setItem("gaming_consoles")} className={"form__content__optionbox__option" + (this.state.items.includes("gaming_consoles") ? " form__content__optionbox__option--selected" : "")}>
                                            <div className="form__content__optionbox__option__illustration form__content__optionbox__option__illustration--gaming_consoles"></div>
                                            <div className="form__content__optionbox__option__label">
                                                Gaming consoles
                                            </div>
                                        </div>
                                    }

                                </div>

                                {this.state.mode && this.state.category && this.state.items.length >= 1 && !this.state.lastStep &&
                                    <div className="form__content__element form__content__buttonbox">
                                        <div onClick={() => this.setLastStep(true)} className="form__content__buttonbox__button button button-primary">
                                            Next
                                        </div>
                                        <div onClick={this.resetItems} className="form__content__buttonbox__button button button-primary-light">
                                            Reset
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {this.state.mode && this.state.category && this.state.lastStep &&
                    <div className="overlay__popup">
                        <div className="form-container">
                            <div className="form__header">
                                <div className="form__header__title">
                                    What is your email?
                                </div>
                                <IconClosePopup onClick={this.closePopup} className="form__header__close" />
                            </div>
                            <div className="form__content">

                                <div className="form__content__element form__content__input">
                                    {<ForminputText
                                        name={"email"}
                                        label={"E-mail for free trial once we are live"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your email to receive additional information."}
                                    />}
                                </div>

                                <div className="form__content__element form__content__buttonbox">
                                    <div onClick={this.submit} className="form__content__buttonbox__button button button-primary">
                                        Submit
                                    </div>
                                    <div onClick={() => this.setLastStep(false)} className="form__content__buttonbox__button button button-primary-light">
                                        Back
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

import React, { Component } from 'react';


export default class Item extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {

        return (
            <div key={this.props.data.id} className={"shop__catalogue__element" + (this.props.data.availability >= 1 && this.props.selected ? " shop__catalogue__element--selected" : "") + (this.props.data.availability < 1 && this.props.selected ? " shop__catalogue__element--selected-wishlist" : "")}>
                <div className="shop__catalogue__element__picture">
                    <img className="shop__catalogue__element__picture-inner" src={require('../../media/images/catalogue/' + this.props.data.picture)} alt={this.props.data.label} />
                    {this.props.data.availability_remaining_list[0].availability < 1 &&
                        <div className="shop__catalogue__element__picture-unavailable">Not available</div>
                    }
                </div>
                <div className="shop__catalogue__element__label text-bold">
                    {this.props.data.label}
                </div>
                {/* <div className="shop__catalogue__element__details">
                    {this.props.data.availability_remaining >= 1 ? "Available" : "Not available"}
                </div> */}
                <div className="shop__catalogue__element__details">
                    {this.props.data.availability_remaining_list[0].availability >= 1
                        ? "Available"
                        : this.props.data.availability_remaining_list[1].availability >= 1
                            ? "Available tomorrow"
                            : this.props.data.availability_remaining_list[2].availability >= 1
                                ? "Available in 2 days"
                                : this.props.data.availability_remaining_list[3].availability >= 1
                                    ? "Available in 3 days"
                                    : this.props.data.availability_remaining_list[4].availability >= 1
                                        ? "Available in 4 days"
                                        : this.props.data.availability_remaining_list[5].availability >= 1
                                            ? "Available in 5 days"
                                            : "Not available"
                    }
                </div>
                <div className="shop__catalogue__element__actionbox">
                    {this.props.data.availability < 1 && !this.props.selected && 
                        <div onClick={() => this.props.addItem(this.props.data)} className="button button-secondary shop__catalogue__element__actionbox__element">
                            Express interest
                        </div>
                    }
                    {this.props.data.availability < 1 && this.props.selected && 
                        <div onClick={() => this.props.removeItem(this.props.data)} className="button button-secondary-light shop__catalogue__element__actionbox__element">
                            Remove
                        </div>
                    }
                    {this.props.data.availability >= 1 && this.props.data.availability_remaining_list[0].availability < 1 && !this.props.selected && !this.props.waitlist &&
                        <div className="button button-inactive shop__catalogue__element__actionbox__element">
                            Unavailable
                        </div>
                    }
                    {this.props.data.availability >= 1 && this.props.data.availability_remaining_list[0].availability < 1 && !this.props.selected && this.props.waitlist &&
                        <div onClick={() => this.props.removeWaitlist(this.props.data)} className="button button-secondary-light shop__catalogue__element__actionbox__element">
                            Remove
                        </div>
                    }
                    {this.props.data.availability_remaining_list[0].availability >= 1 && !this.props.selected &&
                        <div onClick={() => this.props.addItem(this.props.data)} className="button button-primary shop__catalogue__element__actionbox__element">
                            Add
                        </div>
                    }
                    {this.props.data.availability_remaining_list[0].availability >= 1 && this.props.selected &&
                        <div onClick={() => this.props.removeItem(this.props.data)} className="button button-primary-light shop__catalogue__element__actionbox__element">
                            Remove
                        </div>
                    }
                </div>
            </div>
        )
    }
}

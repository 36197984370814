import React, { Component } from 'react';
import Inventoryitem from './inventoryitem.js';


export default class Inventory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            items: [],
            items_filtered: [],
            filter_category: "",
            categories: []
        };

        this.loadItemList = this.loadItemList.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.setCategory = this.setCategory.bind(this);
        this.filterItems = this.filterItems.bind(this);
    }


    componentDidMount() {
        this.loadItemList();
    }


    componentDidUpdate() {
        this.loadItemList();
    }


    async loadItemList() {
        if (this.props.items && this.props.rentals_ongoing && this.props.rentals_future && this.state.items.length < 1) {



            const items = this.props.items.map((i) => {
                var rentallist_ongoing = [];
                var rentallist_future = [];
                var rentallist_past = [];
                this.props.rentals_ongoing.map((r) => {
                    if (r.item_id === i.id) {
                        rentallist_ongoing.push(r);
                    }
                });
                this.props.rentals_future.map((r) => {
                    if (r.item_id === i.id) {
                        rentallist_future.push(r);
                    }
                });
                this.props.rentals_past.map((r) => {
                    if (r.item_id === i.id) {
                        rentallist_past.push(r);
                    }
                });
                return { ...i, rentals_ongoing: rentallist_ongoing, rentals_future: rentallist_future, rentals_past: rentallist_past };
            });




            await this.setState({
                items: items,
                items_filtered: items
            });
            await this.getCategories();
            await this.filterItems();
        }
    }


    getCategories() {
        var list = this.props.items;
        var categories = [...new Set(list.map(item => item.category))];
        this.setState({
            categories: categories
        });
    }


    async setCategory(category) {
        await this.setState({
            filter_category: category
        });
        await this.filterItems();
    }


    async filterItems() {
        var list_filtered = this.state.items;
        if (this.state.filter_category !== "") {
            list_filtered = this.state.items.filter(item => item.category === this.state.filter_category);
        }
        this.setState({
            items_filtered: list_filtered
        });
    }


    render() {
        return (
            <div className="rentallist-container">
                <div className="rentallist-wrapper">

                    <div className="shop__categories">
                        <div className="shop__categories__section">
                            <div className="shop__categories__title">
                                Category:
                            </div>
                            <div onClick={() => this.setCategory("")} className={"shop__categories__element" + (this.state.filter_category === "" ? " shop__categories__element--selected" : "")}>
                                All
                            </div>
                            {this.state.categories.length >= 1 && this.state.categories.map((cat, i) =>
                                <div key={i} onClick={() => this.setCategory(cat)} className={"shop__categories__element" + (this.state.filter_category === cat ? " shop__categories__element--selected" : "")}>
                                    {cat}
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="inventory">

                        {this.state.items_filtered.length >= 1 && this.state.items_filtered.map((item, id) =>
                            <Inventoryitem
                                key={id}
                                data={item}
                            />
                        )}
                    </div>

                </div>
            </div>
        )
    }
}

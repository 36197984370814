import React, { Component } from 'react';
import { ReactComponent as IconClosePopup } from '../../media/icons/popup_close.svg';
import ForminputText from '../forminput/forminput_text.js';
import ForminputDate from '../forminput/forminput_date.js';


export default class Checkout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            page: 1
        };

        this.setPage = this.setPage.bind(this);
        this.setFormValue = this.setFormValue.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
    }


    setPage(page) {
        // Update state
        this.setState({
            page: page
        });
    }


    setFormValue(label, value) {
        // Update state
        this.setState({
            [label]: value
        });
    }


    submitRequest() {
        // Validate form
        var check = true;
        if (!this.state.date_from || this.state.date_from === "") {
            check = false;
            alert("Please provide a start date");
            return false;
        }
        const date_today = new Date();
        date_today.setUTCHours(12, 0, 0, 0);
        const date_from = new Date(this.state.date_from);
        date_from.setUTCHours(12, 0, 0, 0);
        const date_to = new Date(this.state.date_to);
        date_to.setUTCHours(12, 0, 0, 0);
        if (date_from.toISOString().substr(0, 10) !== date_today.toISOString().substr(0, 10)) {
            check = false;
            alert("Start date needs to be today");
            return false;
        }
        if (!this.state.date_to || this.state.date_to === "") {
            check = false;
            alert("Please provide an end date");
            return false;
        }
        // Check if rental period is not too long
        var timeDiff = date_to.getTime() - date_from.getTime();
        var dayDiff = timeDiff / (1000 * 3600 * 24);
        if (dayDiff > 7) {
            check = false;
            alert("Please select a rental period for at most 7 days");
            return false;
        }
        if (!this.state.room || this.state.room === "") {
            check = false;
            alert("Please provide your room number");
            return false;
        }
        if (!this.state.name || this.state.name === "") {
            check = false;
            alert("Please provide your name");
            return false;
        }
        if (!this.state.email || this.state.email === "") {
            check = false;
            alert("Please provide your email");
            return false;
        }
        if (check) {
            this.props.submitRequest(this.state.date_from, this.state.date_to, this.state.room, this.state.name, this.state.email);
            this.setPage(3);
        }
    }


    render() {

        return (
            <div className="overlay">
                <div className="overlay__popup">
                    <div className="form-container">
                        <div className="form__header">
                            <div className="form__header__title">
                                Checkout
                            </div>
                            <IconClosePopup onClick={() => this.props.setCheckout(false)} className="form__header__close" />
                        </div>

                        {this.state.page === 1 &&
                            <div className="form__content">
                                <div className="form__content__element text-bold">
                                    Please review the {this.props.cart.length} items you selected to proceed.
                                </div>
                                <div className="form__content__element form__content__element__cart">
                                    {this.props.cart.map(item =>
                                        <div key={item.id} className="form__content__element__cart__element">
                                            1x {item.label}
                                        </div>
                                    )}
                                </div>
                                <div className="form__content__element form__content__buttonbox">
                                    <div onClick={() => this.setPage(2)} className="form__content__buttonbox__button button button-primary">
                                        Next
                                    </div>
                                    <div onClick={() => this.props.setCheckout(false)} className="form__content__buttonbox__button button button-primary-light">
                                        Cancel
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.page === 2 &&
                            <div className="form__content">
                                <div className="form__content__element form__content__input form__content__element--double-columns">
                                    {<ForminputDate
                                        name={"date_from"}
                                        label={"Rent from"}
                                        default_value={new Date().toISOString().substr(0, 10)}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"The start date for your rental."}
                                    />}
                                    {<ForminputDate
                                        name={"date_to"}
                                        label={"Rent until"}
                                        default_value={new Date(Date.now() + 86400000).toISOString().substr(0, 10)}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"The end date for your rental."}
                                    />}
                                </div>
                                <div className="form__content__element form__content__input form__content__element--double-columns">
                                    {<ForminputText
                                        name={"room"}
                                        label={"Your room number"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your room number that you ant these items delivered to."}
                                    />}
                                    {<ForminputText
                                        name={"name"}
                                        label={"Your name"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your name as listed on your reservation."}
                                    />}
                                </div>
                                <div className="form__content__element form__content__input">
                                    {<ForminputText
                                        name={"email"}
                                        label={"Your e-mail for additional questions"}
                                        setFormValue={this.setFormValue.bind(this)}
                                        required={true}
                                        note={"Your email to receive additional information."}
                                    />}
                                </div>
                                <div className="form__content__element form__content__buttonbox">
                                    <div onClick={this.submitRequest.bind(this)} className="form__content__buttonbox__button button button-primary">
                                        Submit request
                                    </div>
                                    <div onClick={() => this.setPage(1)} className="form__content__buttonbox__button button button-primary-light">
                                        Back
                                    </div>
                                </div>
                            </div>
                        }

                        {this.state.page === 3 &&
                            <div className="form__content">
                                <div className="form__content__element">
                                    Your order has been received and will be delivered to your room soon.
                                </div>
                                <div className="form__content__element form__content__buttonbox">
                                    <div onClick={() => this.props.setCheckout(false)} className="form__content__buttonbox__button button button-primary">
                                        Close
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </div>
            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Rentallist from './rentallist.js';
import Inventory from './inventory.js';


export default class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            items: [],
            page: "rentals",
            date: new Date()
        };

        this.baseUrl = this.props.baseUrl;
        this.loadHotelData = this.loadHotelData.bind(this);
        this.loadHotelRentalData = this.loadHotelRentalData.bind(this);
        this.createItemList = this.createItemList.bind(this);
        this.setPage = this.setPage.bind(this);
    }


    async componentDidMount() {
        this.props.setLoading();
        var hotelid = this.props.match.params.hotelid;
        const date_today = new Date();
        // TO DO: Adjust time based on local (hotel) time
        date_today.setUTCHours(12, 0, 0, 0);
        this.setState({
            hotelid: hotelid,
            date: date_today
        });
        await this.loadHotelData(hotelid);
        await this.loadHotelRentalData(hotelid);
        this.props.unsetLoading();
    }


    async loadHotelData(hotelid) {
        try {
            await axios.post(this.baseUrl + "/api/v001/gethoteldata", {
                hotel_id: hotelid
            })
            .then(res => {
                var data = res.data;

                if (data.id) {
                    // Set data to state
                    this.setState({
                        hoteldata: data
                    });
                } else {
                    // Track error
                    var name = "Server error";
                    var message = "Could not load hotel data. Please check the URL and try again.";
                    this.props.setError(name, message);
                }
            })
            .catch(err => {
                // Track error
                var name = "Server error";
                var message = "Could not load hotel data. Please check the URL and try again.";
                this.props.setError(name, message);
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            var name = "Server error";
            var message = "Could not load hotel data. Please check the URL and try again.";
            this.props.setError(name, message);
            // Track event
            /* this.trackEvent("Client error", "Hotel_loadHotelData"); */
        }
    }


    async loadHotelRentalData(hotelid) {
        try {
            await axios.post(this.baseUrl + "/api/v001/gethotelorders", {
                hotel_id: hotelid
            })
            .then(res => {
                var data = res.data;

                if (data) {
                    // Sort rental data by date
                    data = [...data].sort((a, b) => new Date(a.date_to) - new Date(b.date_to));
                    data = [...data].sort((a, b) => new Date(b.date_from) - new Date(a.date_from));
                    // Filter rental data by date
                    const date_today = new Date();
                    date_today.setUTCHours(12, 0, 0, 0);
                    const rentals_past = data.filter((rental) => {
                        const rental_date_to = new Date(rental.date_to);
                        rental_date_to.setUTCHours(12, 0, 0, 0);
                        return rental_date_to < date_today;
                    });
                    const rentals_ongoing = data.filter((rental) => {
                        const rental_date_from = new Date(rental.date_from);
                        rental_date_from.setUTCHours(12, 0, 0, 0);
                        const rental_date_to = new Date(rental.date_to);
                        rental_date_to.setUTCHours(12, 0, 0, 0);
                        return rental_date_from <= date_today && rental_date_to >= date_today;
                    });
                    const rentals_future = data.filter((rental) => {
                        const rental_date_from = new Date(rental.date_from);
                        rental_date_from.setUTCHours(12, 0, 0, 0);
                        return rental_date_from > date_today;
                    });

                    // Set data to state
                    this.setState({
                        rentals_past: rentals_past,
                        rentals_ongoing: rentals_ongoing,
                        rentals_future: rentals_future,
                        rentals_all: data
                    });

                    this.createItemList();
                } else {
                    // Track error
                    // TO DO
                }
            })
            .catch(err => {
                // Track error
                // TO DO
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            // TO DO
            /* var name = "Website error";
            var message = "Website error"; */
            /* this.props.setError(name, message); */
            // Track event
            /* this.trackEvent("Client error", "Hotel_loadHotelData"); */
        }
    }


    async createItemList() {
        var items = this.state.hoteldata.items;
        var rentals = this.state.rentals_all;

        // Iterate over all items
        items.forEach((item, index_item) => {
            // Calculate availability for each day
            item.availability_remaining_list = [];
            for (let i = 0; i <= 7; i++) {
                const date = new Date();
                date.setDate(date.getDate() + i);
                date.setUTCHours(12, 0, 0, 0);
                const number_rented = rentals.filter((rental) => {
                    const rental_date_from = new Date(rental.date_from);
                    rental_date_from.setUTCHours(12, 0, 0, 0);
                    const rental_date_to = new Date(rental.date_to);
                    rental_date_to.setUTCHours(12, 0, 0, 0);
                    return rental.item_id === item.id && rental_date_to >= date && rental_date_from <= date;
                }).length;
                item.availability_remaining_list[i] = {
                    date: date.toISOString(),
                    availability: item.availability - number_rented
                };
            }
            item.availability_remaining = item.availability_remaining_list[0].availability;
        });

        this.setState({
            items: items
        });
    }


    setPage(page) {
        this.setState({
            page: page
        });
    }


    render() {
        return (
            <div className="content-container">

                {this.state.hoteldata &&
                    <div className="content-wrapper">

                        <div className="hotel-header-container">
                            <div className="hotel-header-wrapper">
                                <div className="hotel-header__detailbox">
                                    <div className="hotel-header__detailbox__element text-bold">
                                        {this.state.hoteldata.name}
                                    </div>
                                    <div className="hotel-header__detailbox__element">
                                        {this.state.hoteldata.address_street}
                                        {this.state.hoteldata.address_street2 ? ", " + this.state.hoteldata.address_street2 : ""}
                                        {", " + this.state.hoteldata.address_city}
                                        {", " + this.state.hoteldata.address_state}
                                        {", " + this.state.hoteldata.address_postcode}
                                        {", " + this.state.hoteldata.address_country}
                                    </div>
                                    <div className="hotel-header__detailbox__element">
                                        <a href={"tel:" + this.state.hoteldata.phone}>{this.state.hoteldata.phone}</a>
                                    </div>
                                    <div className="hotel-header__detailbox__element">
                                        <a href={this.state.hoteldata.website}>Website</a>
                                    </div>
                                </div>
                                <div className="hotel-header__logo">
                                    <img className="hotel-header__logo-inner" src={require('../../media/images/hotel_logos/' + this.state.hoteldata.logo_filename)} alt="Hotel logo" />
                                </div>
                            </div>
                        </div>

                        <div className="dashboard__navbar-container">
                            <div className="dashboard__navbar-wrapper">
                                <div onClick={() => this.setPage("rentals")} className={"dashboard__navbar__element button" + (this.state.page === "rentals" ? " button-primary" : " button-tertiary")}>
                                    Rentals
                                </div>
                                <div onClick={() => this.setPage("inventory")} className={"dashboard__navbar__element button" + (this.state.page === "inventory" ? " button-primary" : " button-tertiary")}>
                                    Inventory
                                </div>
                                {/* <div className="dashboard__navbar__element button button-tertiary">
                                    Settings
                                </div> */}
                                <Link to={"/hotel/1001"} className={"dashboard__navbar__element button button-tertiary"}>
                                    New rental
                                </Link>
                            </div>
                        </div>

                        {this.state.page === "rentals" &&
                            <Rentallist
                                rentals_all={this.state.rentals_all}
                                rentals_past={this.state.rentals_past}
                                rentals_ongoing={this.state.rentals_ongoing}
                                rentals_future={this.state.rentals_future}
                                date_today={this.state.date}
                                hotelid={this.state.hotelid}
                                baseUrl={this.props.baseUrl}
                                logEvent={this.props.logEvent}
                                setLoading={this.props.setLoading}
                                unsetLoading={this.props.unsetLoading}
                            />
                        }

                        {this.state.page === "inventory" &&
                            <Inventory
                                items={this.state.items}
                                rentals_past={this.state.rentals_past}
                                rentals_ongoing={this.state.rentals_ongoing}
                                rentals_future={this.state.rentals_future}
                                date_today={this.state.date}
                                logEvent={this.props.logEvent}
                                setLoading={this.props.setLoading}
                                unsetLoading={this.props.unsetLoading}
                            />
                        }

                    </div>
                }
            </div>
        )
    }
}

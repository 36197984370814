import React, { Component } from 'react';
import axios from 'axios';
import Shop from './shop.js';
import Wishlist from './wishlist.js';
import Confirmation from './confirmation.js';


export default class Hotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            itemList: [],
            date: new Date(),
            submitted: 0
        };

        this.baseUrl = this.props.baseUrl;
        this.loadHotelData = this.loadHotelData.bind(this);
        this.loadHotelRentalData = this.loadHotelRentalData.bind(this);
        this.createItemList = this.createItemList.bind(this);
        this.submitRentRequest = this.submitRentRequest.bind(this);
        this.submitWishlistRequest = this.submitWishlistRequest.bind(this);
    }


    async componentDidMount() {
        this.props.setLoading();
        var hotelid = this.props.match.params.hotelid;
        const date_today = new Date();
        // TO DO: Adjust time based on local (hotel) time
        date_today.setUTCHours(12, 0, 0, 0);
        this.setState({
            hotelid: hotelid,
            date: date_today
        });
        await this.loadHotelData(hotelid);
        await this.loadHotelRentalData(hotelid);
        await this.createItemList();
        this.props.unsetLoading();
    }


    async loadHotelData(hotelid) {
        try {
            await axios.post(this.baseUrl + "/api/v001/gethoteldata", {
                hotel_id: hotelid
            })
            .then(res => {
                var data = res.data;

                if (data.id) {
                    // Set data to state
                    this.setState({
                        hoteldata: data
                    });
                } else {
                    // Track error
                    var name = "Server error";
                    var message = "Could not load hotel data. Please check the URL and try again.";
                    this.props.setError(name, message);
                }
            })
            .catch(err => {
                // Track error
                var name = "Server error";
                var message = "Could not load hotel data. Please check the URL and try again.";
                this.props.setError(name, message);
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            var name = "Server error";
            var message = "Could not load hotel data. Please check the URL and try again.";
            this.props.setError(name, message);
            // Track event
            /* this.trackEvent("Client error", "Hotel_loadHotelData"); */
        }
    }


    async loadHotelRentalData(hotelid) {
        try {
            await axios.post(this.baseUrl + "/api/v001/gethotelorders", {
                hotel_id: hotelid
            })
            .then(res => {
                var data = res.data;

                if (data) {
                    // Filter rental data by date
                    const date_today = new Date();
                    date_today.setUTCHours(12, 0, 0, 0);
                    const data_filtered = data.filter((rental) => {
                        const rental_date_from = new Date(rental.date_from);
                        rental_date_from.setUTCHours(12, 0, 0, 0);
                        const rental_date_to = new Date(rental.date_to);
                        rental_date_to.setUTCHours(12, 0, 0, 0);
                        return rental_date_to >= date_today;
                    });

                    // Set data to state
                    this.setState({
                        rental_data: data,
                        rental_data_filtered: data_filtered
                    });
                } else {
                    // Track error
                    // TO DO
                }
            })
            .catch(err => {
                // Track error
                // TO DO
            });
            window.scrollTo(0, 0);
        } catch (error) {
            // Track error
            // TO DO
            /* var name = "Website error";
            var message = "Website error"; */
            /* this.props.setError(name, message); */
            // Track event
            /* this.trackEvent("Client error", "Hotel_loadHotelData"); */
        }
    }


    async createItemList() {
        var items = this.state.hoteldata.items;
        var rentals = this.state.rental_data_filtered;

        // Iterate over all items
        items.forEach((item, index_item) => {
            // Calculate availability for each day
            item.availability_remaining_list = [];
            for (let i = 0; i <= 7; i++) {
                const date = new Date();
                date.setDate(date.getDate() + i);
                date.setUTCHours(12, 0, 0, 0);
                const number_rented = rentals.filter((rental) => {
                    const rental_date_from = new Date(rental.date_from);
                    rental_date_from.setUTCHours(12, 0, 0, 0);
                    const rental_date_to = new Date(rental.date_to);
                    rental_date_to.setUTCHours(12, 0, 0, 0);
                    return rental.item_id === item.id && rental_date_to >= date && rental_date_from <= date;
                }).length;
                item.availability_remaining_list[i] = {
                    date: date.toISOString(),
                    availability: item.availability - number_rented
                };
            }
            item.availability_remaining = item.availability_remaining_list[0].availability;
        });

        this.setState({
            itemList: items
        });
    }


    async submitRentRequest(cart, waitlist, date_from, date_to, room, name, email_guest) {
        this.props.setLoading();

        // Update rental in database
        try {
            var rental_data_json = [];
            cart.forEach((item, index) => {
                var rental_data_item = {
                    item_id: item.id,
                    item_label: item.label,
                    item_category: item.category,
                    item_description: item.description,
                    item_amount: 1,
                    date_from: date_from,
                    date_to: date_to,
                    room: room,
                    name: name,
                    email_guest: email_guest,
                    status: ["requested"],
                    timestamp: this.state.date.toISOString()
                }
                rental_data_json.push(rental_data_item);
            });

            // Submit request to server
            axios.post(this.baseUrl + "/api/v001/submitrequest", {
                list: rental_data_json,
                date_from: date_from,
                date_to: date_to,
                guest_name: name,
                guest_email: email_guest,
                guest_room: room,
                hotel_id: this.state.hoteldata.id,
                hotel_name: this.state.hoteldata.name,
                hotel_email: this.state.hoteldata.email
            })
            .then((response) => {
                if (response.data.status === true) {
                } else {
                    // Track error
                    // TO DO
                }
            })
            .catch((error) => {
                // Track error
                // TO DO
            });

        } catch (error) {
            // Track error
            // TO DO
        }

        await this.loadHotelRentalData(this.state.hoteldata.id);
        await this.createItemList();
        this.setState({
            submitted: 1
        });
        this.props.unsetLoading();
    }


    async submitWishlistRequest(wishlist) {
        this.props.setLoading();
        // Update wishlist in database
        try {
            if (wishlist.length >= 1) {
                // Get latest wishlist data
                var wishlist_data_json = [];
                wishlist.forEach((item, index) => {
                    var wishlist_data_item = {
                        item_id: item.id,
                        item_label: item.label,
                        item_category: item.category,
                        item_description: item.description,
                        timestamp: this.state.date.toISOString()
                    }
                    wishlist_data_json.push(wishlist_data_item);
                });

                // Submit request to server
                axios.post(this.baseUrl + "/api/v001/submitwishlistrequest", {
                    list: wishlist_data_json,
                    hotel_id: this.state.hoteldata.id
                })
                .then((response) => {
                    if (response.data.status === true) {
                    } else {
                        // Track error
                        // TO DO
                        console.log("error");
                    }
                })

                .catch((error) => {
                    // Track error
                    // TO DO
                    console.log("error");
                });
            }
        } catch (error) {
            // Track error
            // TO DO
            console.log("error");
        }
        this.setState({
            submitted: 2
        });
        this.props.unsetLoading();
    }


    render() {
        return (
            <div className="content-container">

                {this.state.hoteldata &&
                    <div className="content-wrapper">
                        <div className="hotel-header-container">
                            <div className="hotel-header-wrapper">
                                <div className="hotel-header__detailbox">
                                    <div className="hotel-header__detailbox__element text-bold">
                                        {this.state.hoteldata.name}
                                    </div>
                                    {/* <div className="hotel-header__detailbox__element">
                                        {this.state.hoteldata.address_street}
                                        {this.state.hoteldata.address_street2 ? ", " + this.state.hoteldata.address_street2 : ""}
                                        {", " + this.state.hoteldata.address_city}
                                        {", " + this.state.hoteldata.address_state}
                                        {", " + this.state.hoteldata.address_postcode}
                                        {", " + this.state.hoteldata.address_country}
                                    </div> */}
                                    <div className="hotel-header__detailbox__element">
                                        <a href={"tel:" + this.state.hoteldata.phone}>{this.state.hoteldata.phone}</a>
                                    </div>
                                    <div className="hotel-header__detailbox__element">
                                        <a href={this.state.hoteldata.website}>Website</a>
                                    </div>
                                </div>
                                <div className="hotel-header__logo">
                                    <img className="hotel-header__logo-inner" src={require('../../media/images/hotel_logos/' + this.state.hoteldata.logo_filename)} alt="Hotel logo" />
                                </div>
                            </div>
                        </div>

                        {this.state.submitted === 0 &&
                            <Shop
                                items={this.state.itemList.filter(item => item.availability >= 1)}
                                submitRentRequest={this.submitRentRequest}
                                logEvent={this.props.logEvent}
                                setLoading={this.props.setLoading}
                                unsetLoading={this.props.unsetLoading}
                            />
                        }

                        {this.state.submitted === 1 &&
                            <Wishlist
                                items={this.state.itemList.filter(item => item.availability < 1)}
                                submitWishlistRequest={this.submitWishlistRequest}
                                logEvent={this.props.logEvent}
                                setLoading={this.props.setLoading}
                                unsetLoading={this.props.unsetLoading}
                            />
                        }

                        {this.state.submitted === 2 &&
                            <Confirmation
                            />
                        }

                    </div>
                }
            </div>
        )
    }
}

import React, { Component } from 'react';
import { ReactComponent as IconDropdown } from '../../media/icons/dropdown_sidebar.svg';


export default class Inventoryitem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
            toggleAvailability: false,
            toggleRentalsOngoing: false,
            toggleRentalsFuture: false,
            toggleRentalsPast: false
        };

        this.toggleAvailability = this.toggleAvailability.bind(this);
        this.toggleRentalsOngoing = this.toggleRentalsOngoing.bind(this);
        this.toggleRentalsFuture = this.toggleRentalsFuture.bind(this);
        this.toggleRentalsPast = this.toggleRentalsPast.bind(this);
    }


    toggleAvailability() {
        this.setState({
            toggleAvailability: !this.state.toggleAvailability
        });
    }


    toggleRentalsOngoing() {
        this.setState({
            toggleRentalsOngoing: !this.state.toggleRentalsOngoing
        });
    }


    toggleRentalsFuture() {
        this.setState({
            toggleRentalsFuture: !this.state.toggleRentalsFuture
        });
    }


    toggleRentalsPast() {
        this.setState({
            toggleRentalsPast: !this.state.toggleRentalsPast
        });
    }


    render() {
        return (
            <div className={"inventory__item" + (this.props.data.availability - this.props.data.rentals_ongoing.length < 1 ? " inventory__item--unavailable" : "")}>
                <div className="inventory__item__picture">
                    <img className="inventory__item__picture-inner" src={require('../../media/images/catalogue/' + this.props.data.picture)} alt={this.props.data.label} />
                    {this.props.data.availability - this.props.data.rentals_ongoing.length < 1 &&
                        <div className="inventory__item__picture--unavailable">
                            Not available
                        </div>
                    }
                </div>
                <div className="inventory__item__content">
                    <div className="inventory__item__content__element">
                        <span className="text-bold">
                            {this.props.data.label}
                        </span>
                        <span>
                            {" (" + this.props.data.category + ")"}
                        </span>
                    </div>
                    <div className="inventory__item__content__element">
                        <span className="text-bold">
                            {(this.props.data.availability - this.props.data.rentals_ongoing.length)}
                        </span>
                        <span>
                            {" available"}
                        </span>
                    </div>
                    <div className="inventory__item__content__element inventory__item__content__element--double">
                        {"Description: " + this.props.data.description}
                    </div>

                    {this.props.data.availability_remaining_list.length >= 1 &&
                        <div className="inventory__item__content__element inventory__item__content__element--double">
                            <div onClick={this.toggleAvailability} className="inventory__item__content__element__title-container">
                                <IconDropdown className={"inventory__item__content__element__title__dropdown" + (this.state.toggleAvailability ? " inventory__item__content__element__title__dropdown--active" : "")} />
                                <div className="inventory__item__content__element__title__textbox">
                                    Availability:
                                </div>
                            </div>
                            <div className={"inventory__item__content__element__availability" + (this.state.toggleAvailability ? "" : " inventory__item__content__element__availability--hidden")}>
                                {this.props.data.availability_remaining_list.map((a, i) =>
                                    <div key={i} className={"inventory__item__content__element__availability__element" + (a.availability >= 1 ? "" : " inventory__item__content__element__availability__element--unavailable")}>
                                        <span>
                                            {new Date(a.date).toLocaleString('default', { month: 'long' }) + " " + new Date(a.date).getDate() + ": "}
                                        </span>
                                        <span className="text-bold">
                                            {a.availability}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                    {this.props.data.rentals_ongoing.length >= 1 &&
                        <div className="inventory__item__content__element inventory__item__content__element--double">
                            <div onClick={this.toggleRentalsOngoing} className="inventory__item__content__element__title-container">
                                <IconDropdown className={"inventory__item__content__element__title__dropdown" + (this.state.toggleRentalsOngoing ? " inventory__item__content__element__title__dropdown--active" : "")} />
                                <div className="inventory__item__content__element__title__textbox">
                                    {this.props.data.rentals_ongoing.length + " ongoing rentals:"}
                                </div>
                            </div>
                            <div className={"inventory__item__content__element__rentalbox" + (this.state.toggleRentalsOngoing ? "" : " inventory__item__content__element__rentalbox--hidden")}>
                                {this.props.data.rentals_ongoing.map((r, i) =>
                                    <div key={i} className="inventory__item__content__element__rental">
                                        <div className="inventory__item__content__element__rental__element">
                                            {r.name}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"Room " + r.room}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"From " + new Date(r.date_from).toLocaleString('default', { month: 'long' }) + " " + new Date(r.date_from).getDate()}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"To " + new Date(r.date_to).toLocaleString('default', { month: 'long' }) + " " + new Date(r.date_to).getDate()}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                    {this.props.data.rentals_future.length >= 1 &&
                        <div className="inventory__item__content__element inventory__item__content__element--double">
                            <div onClick={this.toggleRentalsFuture} className="inventory__item__content__element__title-container">
                                <IconDropdown className={"inventory__item__content__element__title__dropdown" + (this.state.toggleRentalsFuture ? " inventory__item__content__element__title__dropdown--active" : "")} />
                                <div className="inventory__item__content__element__title__textbox">
                                    {this.props.data.rentals_future.length + " future rentals:"}
                                </div>
                            </div>
                            <div className={"inventory__item__content__element__rentalbox" + (this.state.toggleRentalsFuture ? "" : " inventory__item__content__element__rentalbox--hidden")}>
                                {this.props.data.rentals_future.map((r, i) =>
                                    <div key={i} className="inventory__item__content__element__rental">
                                        <div className="inventory__item__content__element__rental__element">
                                            {r.name}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"Room " + r.room}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"From " + r.date_from}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"To " + r.date_to}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                    {this.props.data.rentals_past.length >= 1 &&
                        <div className="inventory__item__content__element inventory__item__content__element--double">
                            <div onClick={this.toggleRentalsPast} className="inventory__item__content__element__title-container">
                                <IconDropdown className={"inventory__item__content__element__title__dropdown" + (this.state.toggleRentalsPast ? " inventory__item__content__element__title__dropdown--active" : "")} />
                                <div className="inventory__item__content__element__title__textbox">
                                    {this.props.data.rentals_past.length + " past rentals:"}
                                </div>
                            </div>
                            <div className={"inventory__item__content__element__rentalbox" + (this.state.toggleRentalsPast ? "" : " inventory__item__content__element__rentalbox--hidden")}>
                                {this.props.data.rentals_past.map((r, i) =>
                                    <div key={i} className="inventory__item__content__element__rental">
                                        <div className="inventory__item__content__element__rental__element">
                                            {r.name}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"Room " + r.room}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"From " + r.date_from}
                                        </div>
                                        <div className="inventory__item__content__element__rental__element">
                                            {"To " + r.date_to}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                </div>
            </div>
        )
    }
}

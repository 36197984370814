import React, { Component } from 'react';
import illustration_benefit_1 from '../../media/images/home/benefit_1.png';
import illustration_benefit_2 from '../../media/images/home/benefit_2.png';
import illustration_benefit_3 from '../../media/images/home/benefit_3.png';


export default class Benefits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // Default states
        };
    }


    render() {

        return (
            <div id="benefits" className="home__section">
                <div className="home__section__title text-title text-color-primary">
                    A true win-win for you and your guests
                </div>
                <div className="home__benefitlist">

                    <div className="home__benefit">
                        <div className="home__benefit__illustration">
                            <img src={illustration_benefit_1} className="home__benefit__illustration__inner" />
                        </div>
                        <div className="home__benefit__label text-subtitle text-bold">
                            Better guest service
                        </div>
                        <div className="home__benefit__description">
                            Stand out amongst hotels and vacation rentals by offering your guests the service they desire. 
                        </div>
                    </div>

                    <div className="home__benefit">
                        <div className="home__benefit__illustration">
                            <img src={illustration_benefit_2} className="home__benefit__illustration__inner" />
                        </div>
                        <div className="home__benefit__label text-subtitle text-bold">
                            More sustainable
                        </div>
                        <div className="home__benefit__description">
                            Let your guests choose what items they need and want so you can serve more guests with fewer items. 
                        </div>
                    </div>

                    <div className="home__benefit">
                        <div className="home__benefit__illustration">
                            <img src={illustration_benefit_3} className="home__benefit__illustration__inner" />
                        </div>
                        <div className="home__benefit__label text-subtitle text-bold">
                            Fully efficient
                        </div>
                        <div className="home__benefit__description">
                            Our platform provides fully digitized and optimized order processes - because we know how busy your staff is. 
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
